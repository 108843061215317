import {useEffect, useState} from 'react';
import {apiheaders} from '../config';
import useInterval from './UseInterval';

const https = require('https');
var axios = require('axios');
var qs = require('qs');
var data = qs.stringify({});
const agent = new https.Agent({  
    rejectUnauthorized: false
  });

  export function useAxiosGet(url, interval=-1, immediate=true){
    var intv = interval;
    var imm = immediate;
    const [request, setRequest] = useState({
        loading: false,
        data: null,
        error: false
    })

    const getResponse = async () =>{
        setRequest({
            loading: true,
            data: null,
            error: false
        });
        var config = {
            method: 'get',
            url: url,
            headers: apiheaders,
            data : data,
            timeout: 30000
          };
          axios(config, {httpsAgent: agent})
            .then(response => {
                setRequest({
                    loading: false,
                    data: response.data,
                    rejectUnauthorized: false,
                    error: false
                })
            })
            .catch(() => {
                setRequest({
                    loading: false,
                    data: null,
                    error: true
                })
            })
    }
    useInterval(getResponse, intv, imm);
    // useEffect(() => {
    //     getResponse()
    //     setInterval(()=>{
    //         getResponse()
    //     }, intv)
    // }, [url])

    return request
}
