import React from "react";
import schema from "./subsysschema_v2.json";
import Table from "./Table";
export default function SubSystem(row) {
  const {
    type,
    oltid,
    level,
    lastdowncause,
    lastuptime,
    gaussian,
    floorplan,
    sn, distance, status,
    ethports,
  } = row.original;
  var subsystems = ethports.filter(sub=>{
    return sub.status !== '-';
  });
  const ontDetail = <table>
    <tbody>
      <tr>
        <td><h8 className="align-middle text-xs">SN: </h8><span
          className="text-xs">{sn}</span>
        </td>
        <td>
          <h8 className="align-middle text-xs">Model: <span className="text-xs">{type}</span>
          </h8>
        </td>
        <td>
          <h8 className="align-middle text-xs">Distance: <span
            className="text-xs">{distance}</span></h8>
        </td>
        <td><h8 className="align-middle text-xs">lastdowncause: <span
          className="text-xs">{lastdowncause}</span></h8>
        </td>
        <td>
          <h8 className="align-middle text-xs">lastuptime: <span className="text-xs">{lastuptime}</span>
          </h8>
        </td>
        <td><h8 className="align-middle text-xs">oltid: <span
          className="text-xs">{oltid}</span></h8>
        </td>
      </tr>
    </tbody>
  </table>
  const new_subsystems = subsystems.map(ssys=>{
    var input = ssys.traffic.in_traffic
    var output = ssys.traffic.out_traffic
    const zeros = parseInt(input) === 0 && parseInt(output) === 0
    ssys["Input(Mbps)"] = zeros? <div className="badge-warning px-2 inline-flex text-lg leading-5 font-semibold rounded-full text-black-900">{input}</div>:<div>{input}</div>
    ssys["Output(Mbps)"] = zeros? <div className="badge-warning px-2 inline-flex text-lg leading-5 font-semibold rounded-full text-black-900">{output}</div>:<div>{output}</div>
    ssys.ethPort = ssys.eth_num
    ssys.ethStat = ssys.status && ssys.status !== '-'? ssys.status : ''
    ssys["Mac_address(s)"] = ssys.mac_address
    ssys.System = ssys.system && ssys.system !== '-'? ssys.system + "(" + ssys.vlanid + ")" : ''
    return ssys
  })

  var  subSys = subsystems && new_subsystems.length > 0? <div className="container p-2">
      <div className="row text-xs">
        <div className="col text-xs">
          <Table headers={Object.keys(schema)} rows={new_subsystems} />
        </div>
      </div>
    </div>:<div></div>

  let subContents =
    <div>
      {ontDetail}
      {subSys}
    </div>
  let floorplanContent = null
  let _floorplan = !floorplan ? "L5.jpg" : floorplan
  if (_floorplan) {
    floorplanContent =
      <div>
        <strong className="p-4">{level} Floorplan</strong>
        {/*<img src={gaussianUrl} alt='gaussian distribution' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid"/>*/}
        <img src={require(`../graph/${_floorplan}`)} alt='floorplan' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid" />
      </div>
  }
  let gaussianContent = null
  let _gaussian = !gaussian ? "LG-M10-1016-ONT10.png" : gaussian

  if (_gaussian && status !== "offline") {
    let gaussianUrl = `https://sr.ectivisecloud.com/graph/${_gaussian}`
    // console.log(gaussianUrl)
    gaussianContent =
      <div><br /><strong className="p-4">Gaussian Distribution</strong>
        {/* <img src={gaussianUrl} alt='gaussian distribution' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid"/> */}
        <img src={require(`../graph/${_gaussian}`)} alt='gaussian distribution' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid" />
      </div>
  }
  return (
    <div>
      {subContents}
      {/* {floorplanContent}
      {gaussianContent} */}
    </div>
  );
};
