import React from 'react'
import { myapiurl, fetchInterval } from '../config';
import { useAxiosGet } from "../Hooks/HttpRequests";
import { getONTCount, getCoreNECount } from '../utils/MockData';
import Loader from "../Components/Loader";
import { Link } from 'react-router-dom';
import { AspectRatio } from 'react-aspect-ratio';
import { Blink } from "@bdchauvette/react-blink";

function sitesMap(sites, allData) {
  
    var siteState = (site, coreneData) => {
        let currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() - 15);
        return new Date(coreneData.datetime) > currentDate;
    }

    const febCoreNEsCount = getCoreNECount(allData.febcorenes);
    const m2CoreNEsCount = getCoreNECount(allData.m2corenes);
    const tchCoreNEsCount = getCoreNECount(allData.tchcorenes);
    const lgCoreNEsCount = getCoreNECount(allData.lgcorenes);
    const febONTsCount = getONTCount(allData.febonts);
    const m2ONTsCount = getONTCount(allData.m2onts);
    const tchONTsCount = getONTCount(allData.tchonts);
    const lgONTsCount = getONTCount(allData.lgonts);
    sites.forEach(site => {
        site.url = "/" + site.siteId;
        // eslint-disable-next-line default-case
        switch (site.siteId) {
            case 'feb':
                site.coreneCount = febCoreNEsCount.total;
                site.ontCount = febONTsCount.total;
                site.coreneCountCritical = febCoreNEsCount.critical;
                site.ontCountCritical = febONTsCount.critical;
                site.siteOk = siteState(site, allData.febcorenes[0]);
                break;
            case 'm2':
                site.coreneCount = m2CoreNEsCount.total;
                site.ontCount = m2ONTsCount.total;
                site.coreneCountCritical = m2CoreNEsCount.critical;
                site.ontCountCritical = m2ONTsCount.critical;
                site.siteOk = siteState(site, allData.m2corenes[0]);
                break;
            case 'tch':
                site.coreneCount = tchCoreNEsCount.total;
                site.ontCount = tchONTsCount.total;
                site.coreneCountCritical = tchCoreNEsCount.critical;
                site.ontCountCritical = tchONTsCount.critical;
                site.siteOk = siteState(site, allData.tchcorenes[0]);
                break;
            case 'lg':
                site.coreneCount = lgCoreNEsCount.total;
                site.ontCount = lgONTsCount.total;
                site.coreneCountCritical = lgCoreNEsCount.critical;
                site.ontCountCritical = lgONTsCount.critical;
                site.siteOk = siteState(site, allData.lgcorenes[0]);
                break
        }
        site._name = site.siteOk ? <div className="text-lg font-medium focus:text-black-900">{site.name}</div>
            : <div className="text-lg font-medium focus:text-red-900"><Blink>{site.name}</Blink></div>

        site._ontCountCritical = site.ontCountCritical > 0 ? <div
            className="px-2 inline-flex text-lg leading-5 font-semibold rounded-full bg-red-100 text-red-900">
            <Blink>{site.ontCountCritical}</Blink>
        </div> :
            <div
                className="px-2 inline-flex text-lg leading-5 rounded-full bg-green-100 text-black-900">
                {site.ontCountCritical}
            </div>

            site._coreneCountCritical = site.coreneCountCritical > 0 ? <div
            className="px-2 inline-flex text-lg leading-5 font-semibold rounded-full bg-red-100 text-red-900">
            <Blink>{site.coreneCountCritical}</Blink>
        </div> :
            <div
                className="px-2 inline-flex text-lg leading-5 rounded-full bg-green-100 text-black-900">
                {site.coreneCountCritical}
            </div>


    })
}

export default function HomeTable() {
    let sites = require("../files/sites.json")
    let apiurl = myapiurl('all')
    // apiurl = "https://mockapi.io" //test code
    const _apiurl = 'https://api.ectivisecloud.com/files/m2data.json'
    let _getontresponse = useAxiosGet(_apiurl)
    const getdataresponse = useAxiosGet(apiurl, fetchInterval)
    if (getdataresponse.error) {
        return <div>
            <div className="bg-blue-300 mb-2 p-3">
                If you see this error. there has problem with your API <a href={apiurl}>{apiurl}</a>
            </div>
            <div className="bg-red-300 p-3">
                There was an error please refresh or try again later.
            </div>
        </div>
    }
    else if (getdataresponse.loading) {
        return <Loader />
    }
    else if (getdataresponse.data) {
        const allData = getdataresponse.data
        const m2Data = JSON.parse(_getontresponse.data)
        //console.log("m2Data.m2corenes:::", m2Data.corenes)
        //console.log("m2Data.m2onts:::", m2Data.onts)
        allData.m2corenes = m2Data.corenes
        allData.m2onts = m2Data.onts
        // allData.m2 = m2Data.corenes
        sitesMap(sites, allData)
    }

    return (
        <div className="md:container md:mx-auto">
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-blue-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-blue-200">
                                <thead className="bg-blue-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-lg font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Site
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-lg font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Core NE
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-lg font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Critical
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-lg font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            ONTs
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-lg font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Critical
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-blue-200">
                                    {sites.map((site) => (
                                        <tr key={site.siteId}>
                                            <Link to={site.url}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-8 w-10">
                                                            {/* <img className="h-8 w-10 " src={site.image} alt=""/> */}
                                                            <AspectRatio ratio="1" style={{ maxWidth: '400px' }}>
                                                                <img className="h-8 w-10 " src={site.image} alt="" />
                                                            </AspectRatio>
                                                        </div>
                                                        <div className="ml-4">
                                                            {site._name}
                                                        </div>
                                                    </div>
                                                </td>
                                            </Link>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-lg text-black-900">{site.coreneCount}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div>
                                                    {site._coreneCountCritical}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-lg text-blue-900">{site.ontCount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div>
                                                    {site._ontCountCritical}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div></div>
    )
}
