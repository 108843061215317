import React from "react";

// import {Link} from "react-router-dom";

function MACCard({devicelist}){
  // console.log('maccard=>', devicelist[0]);

  // if(false && !devicelist) return <div>loading</div>
  /*
  Data Structure
  {"total": totalMac, "diffmac": diffMac, "newmac": newMac}
  */
var totalMac = devicelist[0].total_mac;
var diffMac = devicelist[0].diff_mac;
var newMac = devicelist[0].new_mac;
var macSumlist = [{"type": "Mac Adddresses","name": "totalMac","total": totalMac, "diff": diffMac, "new": newMac}];
var deviceName = "Mac Adddresses";
// console.log(devicelist);
// const warning = 'badge-warning'
// var space3 = <span>      </span>
const space30 = <span>                                    </span>
return (
    <>
      {macSumlist.map(device => {
        var newBadgeWarning = device.new > 0?
        <div
            className="badge-warning px-2 inline-flex text-lg leading-5 font-semibold rounded-full text-black-900">
            {device.new}
        </div> :
        <div
            className="badge-success px-2 inline-flex text-lg leading-5 rounded-full text-black-900">
            {device.new}
        </div>
        var diffBadgeWarning = device.diff > 0? 
        <div
            className="badge-warning px-2 inline-flex text-lg leading-5 font-semibold rounded-full text-black-900">
            {device.diff}
        </div> :
            <div
                className="badge-success px-2 inline-flex text-lg leading-5 rounded-full text-black-900">
                {device.diff}
            </div>

        // const act = device.diff !== 0 ? "A" : "S"
            return (
                <div className="border-4 text-left sm:text-center md:text-right lg:text-justify xl:text-center" key={deviceName}>
                  <div className="px-6 py-2">
                  <span className="rounded-circle badge-primary text-sm">feb</span>{device.type}
                  {/* {device.name} */}
                  <div className="font-bold text-sm mb-2 pr-100">Total: <span>{space30}</span>
                    <span
                        className="badge-success text-sm px-100">{space30}{device.total}
                    </span>
                    </div>
                    {/* <div className="font-bold text-sm mb-2"><span className="rounded-circle badge-primary text-sm">{act}</span>{space3}{deviceName}{space3}<span
                        className="badge-success text-sm">{device.total}</span>
                    </div> */}
                    <div className="px-2">
                      <div className="flex -mx-2 text-sm">
                        Differences:
                        <div className="w-auto px-2">
                          {diffBadgeWarning}
                        </div>
                        New:
                        <div className="w-auto px-2">
                          {newBadgeWarning}
                        </div>
                        {/*Power :*/}
                        {/*<div className="w-auto px-2">*/}
                        {/*  <div className="bg-gray-300 text-black text-sm-left sm:text-center md:text-right lg:text-justify xl:text-center">{device.power.chassis} W</div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-100 px-6">
                    <div>Power :  <span className="bg-gray-300 text-black text-left sm:text-center md:text-right lg:text-justify xl:text-center">{device.power.chassis} W</span></div>
                  </div> */}

                </div>
            )
          }
      )}
    </>
)
}

export default MACCard
