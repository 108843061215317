import React, {useMemo} from 'react';
import Loader from '../Components/Loader';
import {useAxiosGet} from '../Hooks/HttpRequests';
import {Container} from 'reactstrap';
import {SelectColumnFilter} from '../Components/filters';
import TableContainer from '../Components/TableContainer';
import CoreNECard from "../Components/CoreNECard";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import {coreneTotalCount, getONTCount} from '../utils/MockData';
import {homeTitleLG as homeTitle, myapiurl} from '../config';
const apiurl = myapiurl('lg');

function Home(){
  // const ontTitle = 'ONT'
  let getontresponse = useAxiosGet(apiurl);
  
//  let corenelist, cslist, fwlist, wswlist, wifilist, pwlist, srvlist
  let content = null, coreneContent;
  let total, offline, ontTotal, ontCritical, ontOffline, critical;
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander', // 'id' is required
        Cell: ({row}) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? '👇' : '👉'}
          </span>
        ),
      },
      {
        Header: 'Name',
        accessor: 'description',
      },
      {
        Header: 'Rx',
        accessor: 'rx',
        disableSortBy: true,
        // Cell: row => {
        //   row.styles['backgroundColor'] = row.status === 'online' && row.value < -20 ? '#cc3300' : 'inherit';
        //   return row.status === 'online' ? row.value : null;
        // }
      },
      {
        Header: 'Tx',
        accessor: 'tx',
        disableSortBy: true,
      },
      {
        Header: 'Port',
        accessor: 'port',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'ID', accessor: 'id', width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      // {
      //   Header: 'Level',
      //   accessor: 'level',
      //   disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      // },
      // {
      //   Header: 'Riser',
      //   accessor: 'riser',
      //   disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      // },
      // {
      //   Header: 'lastdowntime',
      //   accessor: 'lastdowntime',
      //   disableSortBy: true,
      // },
      // {
      //   Header: 'lastuptime',
      //   accessor: 'lastuptime',
      //   disableSortBy: true,
      // },
      // {
      //   Header: 'lastdowncause',
      //   accessor: 'lastdowncause',
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      //   disableSortBy: true,
      // },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: rowInfo && rowInfo.row.age > 10 ? 'red' : null,
            },
          };
        },
      },
    ],
    []
  );

  // error handling while there's error, e.g. use http for https api url
  if(getontresponse.error){
    content = <div>
      <div className="bg-blue-300 mb-2 p-3">
        If you see this error. Please remember to create your own <a href="https://mockapi.io/">mock API</a>.
      </div>
      <div className="bg-red-300 p-3">
        There was an error please refresh or try again later.
      </div>
    </div>
  }

  // will show loading animation while calling the API
  if(getontresponse.loading){
    // content = <Loader/>
    return <Loader/>
  }

  // data ready
  if(getontresponse.data){
    const resData = getontresponse.data;
    // console.log('home resData==>', resData)
    var ontlist = resData.onts
    var coreneList = resData.corenes

    let oltlist = [] 
    let wswlist = [] 
    let fwlist = [] 
    let cslist = []
    let wifilist = []
    let pwlist = []
    let srvlist = []
    coreneList.forEach(ne=>{
      switch(ne.type.toUpperCase()){
        case 'OLT':
          oltlist.push(ne);
          break;
        case 'FW':
          fwlist.push(ne)
          break;
        case 'CS':
          cslist.push(ne)
          break;
        case 'WSW':
          wswlist.push(ne)
          break;
        case 'WIFI':
          wifilist.push(ne)
          break;
        case 'POWER':
          pwlist.push(ne)
          break;
        case 'SERVER':
          srvlist.push(ne)
          break;
        default:
          break;
        }
    })
    var corenelist = {"cslist":cslist,"fwlist":fwlist,"oltlist":oltlist,"wswlist":wswlist,"wifilist":wifilist,"pwlist":pwlist,"srvlist":srvlist}
    // console.log('home corenelist==>', corenelist)

    ontlist.forEach(ont=>{
      if (ont.status === 'offline'){
        ont.rx = '-';
        ont.tx = '-';
      }
    })
    const ct = coreneTotalCount(corenelist)
    total = ct.total
    offline = ct.offline
    critical = ct.critical===0? <a style={{color: "green"}}>0</a>: <a style={{color: "red"}}>{ct.critical}</a>

    var ontdatetime = ontlist[0].datetime
    var corenedatetime = oltlist[0].datetime

    const cnt = getONTCount(ontlist)
    ontTotal = cnt.total
    ontOffline = cnt.offline
    ontCritical = cnt.offline===0? <a style={{color: "green"}}>0</a>: <a style={{color: "red"}}>{cnt.offline}</a>
    content = <Container style={{marginTop: 20}}>
      <TableContainer
        columns={columns}
        data={ontlist}
      />
    </Container>
    coreneContent = <CoreNECard corenelist={corenelist}/>
  }
  return (
    <div className="container mx-auto">
      <div className="font-bold text-2xl mb-3 px-2 pt-2">
        <div className='flex flex-row'>
        <img className='rounded  h-12 w-16 pb-2' src="https://www.legrove.com.sg/themes/custom/legrove/images/logo.png"></img>
          {homeTitle}</div>
        <div className="font-bold text-2xl mb-3 pt-1">Core NEs
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Total {total}</span>
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Offline {offline}</span>
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Critical {critical }</span>
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">last updated@{corenedatetime}</span>
        </div>
      </div>
      {coreneContent}

      <div className="font-bold text-2xl mb-3 pt-2">
        ONTs
      </div>

      <Accordion>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Total {ontTotal}</span>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Offline {ontOffline}</span>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Critical {ontCritical }</span>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">last updated@{ontdatetime}</span>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="md:flex flex-wrap md:-mx-3">
              {content}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

    </div>
  )
}

export default Home
