import React from 'react'

function About(){
    return (
        <div>
            <h1 className="font-bold text-2xl mb-3">About us</h1>
            <p>
                This is the about page content.
            </p>
        </div>
    )
}

export default About