import React, { useMemo } from 'react';
import Loader from '../Components/Loader'
import jsondata from '../files/engenius.json'
import { useAxiosGet } from '../Hooks/HttpRequests_eng'
import {Container} from 'reactstrap';
import { SelectColumnFilter } from '../Components/filters';
import TableContainer from '../Components/TableContainer';
import { OLT_API_URL } from '../config'
import { apTotalCount } from '../utils/MockData'

// let ap_url = "https://falcon.production.engenius.ai/v2/orgs/5fe068a291fddb0893e90761/hvs/5fe068a291fddb0893e90762/networks/5fe068a491fddb0893e9076b/devices/aps"
const useLocalData = false

function Home() {
  let get_ap_list = useAxiosGet(OLT_API_URL)
  let content = null
  let title = "Surbana House Access Points"
  let datetime1 = Date(Date.now()).toString()
  let datetime = datetime1.substr(0, 25)
  let total = 8
  let online = 8
  let offline = 0;
  // let critical;

  const columns = useMemo(
    () => [
      // {
      //   Header: () => null,
      //   id: 'expander', // 'id' is required
      //   Cell: ({row}) => (
      //     <span {...row.getToggleRowExpandedProps()}>
      //       {row.isExpanded ? '👇' : '👉'}
      //     </span>
      //   ),
      // },
      {
        Header: 'Name',
        accessor: 'name',
        width: 15
      },
      {
        Header: 'Clients',
        // accessor: 'information.current_client_count',
        accessor: 'current_client_count',
        width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'tx 5g',
        // accessor: 'information.tx_power["5G"]',
        accessor: 'tx_5g',
        disableSortBy: true,
      },
      {
        Header: 'tx 2.4g',
        // accessor: 'information.tx_power["2_4G"]',
        accessor: 'tx24g',
        disableSortBy: true,
      },
      {
        Header: 'latency',
        // accessor: 'information.ping_latency',
        accessor: 'ping_latency',
        disableSortBy: true,
        width: 5,
      },
      {
        Header: 'cpu %',
        // accessor: 'information.ping_latency',
        accessor: 'cpu_loading',
        disableSortBy: true,
        width: 5,
      },
      {
        Header: 'mem %',
        // accessor: 'information.ping_latency',
        accessor: 'mem_usage',
        disableSortBy: true,
        width: 5,
      },
      // {
      //   Header: 'online since',
      //   // accessor: 'information.last_seen',
      //   accessor: 'last_seen',
      //   disableSortBy: true,
      // },
      {
        Header: 'Status',
        // accessor: 'information.status',
        accessor: 'status',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: rowInfo && rowInfo.row.age > 10 ? 'red' : null,
            },
          };
        },
      },
    ],
    []
  );

  if (get_ap_list.error) {
    content = <div>
      <div className="bg-blue-300 mb-2 p-3">
        If you see this error. Please report to Ectivise Cloud Administrator <a href="mailto://support@ectivise.com/">Ectivise
          Cloud Support</a>.
      </div>
      <div className="bg-red-300 p-3">
        There was an error please refresh or try again later.
      </div>
    </div>
  }

  if (get_ap_list.loading) {
    content = <Loader />
  }

  if (get_ap_list.data) {
    //TODO: Get the data from json file, collected by cronjob, instead of engenius API
    let ap_list = get_ap_list.data.aps //keep for a while
    const ct = apTotalCount(ap_list)
    total = ct.total
    online = total - ct.critical
    offline = ct.critical === 0 ? <a style={{ color: "green" }}>0</a> : <a style={{ color: "red" }}>{ct.critical}</a>


    if (useLocalData) {
      let newaplist = getnewaplist(jsondata)
      ap_list = newaplist.aps
      if (jsondata.datetime) {
        datetime = jsondata.datetime
      }
    }
    content = <Container style={{ marginTop: 20 }}>
      <TableContainer
        columns={columns}
        data={ap_list}
      />
    </Container>
  }

  return (
    <div className="container mx-auto">
      {/*<div className="font-bold text-2xl mb-3 px-2">*/}
      {/*  <div>{site_title}*/}
      {/*    <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Total 2</span>*/}
      {/*    <span*/}
      {/*      className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Online 2</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<OLTCard oltlist={networkswitch}/>*/}

      <div className="font-bold text-2xl mb-3 pt-3">
        <div className='flex flex-row'>
          <img className='rounded  h-12 w-18 pb-2' src="https://www.austcham.org.sg/wp-content/uploads/2020/04/SurbanaJurong_Logo-300x118.jpg" />
          {title}
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">   Last updated@{datetime}</span>
        </div>
      </div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button className="px-2">Total <span className="badge">{total}</span></button>
        <button className="px-2">Online<span className="badge">{online}</span></button>
        <button className="px-2">Offline<span className="badge">{offline}</span></button>
      </div>

      <div className="md:flex flex-wrap md:-mx-3">
        {content}
      </div>
    </div>
  )
}

function timestamp2date(timestamp) {
  var ts_ms = timestamp //* 1000
  var date_ob = new Date(ts_ms);
  // year as 4 digits (YYYY)
  var year = date_ob.getFullYear();

  // month as 2 digits (MM)
  var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // date as 2 digits (DD)
  var date = ("0" + date_ob.getDate()).slice(-2);

  // hours as 2 digits (hh)
  var hours = ("0" + date_ob.getHours()).slice(-2);

  // minutes as 2 digits (mm)
  var minutes = ("0" + date_ob.getMinutes()).slice(-2);

  // seconds as 2 digits (ss)
  var seconds = ("0" + date_ob.getSeconds()).slice(-2);
  let retval = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
  // console.log("Date as YYYY-MM-DD hh:mm:ss Format: " + year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);
  return retval
}

function getnewaplist(json_data) {
  let newAP_List = { aps: [] }
  json_data.aps.forEach(ap => {
    let _ap = {
      name: ap.name,
      model: ap.model,
      mac: ap.mac,
      ip: ap.information.lan.ip,
      wan_ip: ap.information.wan_ip,
      status: ap.information.status,
      // client_count: ap.client_count,
      ping_latency: ap.information.ping_latency,
      cpu_loading: ap.information.cpu_loading,
      mem_usage: ap.information.mem_usage,
      tx_5g: ap.information.tx_power["5G"],
      tx24g: ap.information.tx_power["2_4G"],
      // tx_power: ap.information.tx_power,
      last_seen: ap.information.last_seen,
      current_client_count: ap.information.current_client_count,
      serial_number: ap.serial_number,
      firmware_version: ap.information.firmware_version
    }
    let last_seen = ap.information.last_seen
    _ap.last_seen = timestamp2date(last_seen)
    newAP_List.aps.push(_ap)
  });
  return newAP_List
}

export default Home
