const Loader = require("../Components/Loader");
const React = require("react");
const { homeTitle } = require("../config");

function getRandomNumberBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function checkSiteOk(site, coreneData){
  let datetime = new Date(coreneData.datetime) 
  // console.log("datetime==>", datetime)
  let currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() - 15)
  // console.log("currentDate==>", currentDate)
  let siteOk = datetime > currentDate
  console.log("siteOk for site",site.name," ==>", siteOk)
  return siteOk
}

function coreneTotalCount(corenelist) {
  var cnt = 0, cntfail = 0
  var keys = Object.keys(corenelist);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (corenelist[key] && corenelist[key].length > 0 && corenelist[key][0]) {
      cnt += corenelist[key].length
      // eslint-disable-next-line no-loop-func
      corenelist[key].forEach(value => {
        if (value.status === 'offline') {
          cntfail++
        }
      })
    }
  }
  return { "total": cnt, "offline": cntfail, "critical": cntfail }
}

function apTotalCount(apList) {
  var cnt = apList.length, cntfail = 0
  var keys = Object.keys(apList);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    var ap = apList[key]
    console.log(ap, ap.status)
    if (ap.status === 'offline') {
      cntfail++
    }
    else {
      console.log("something wrong")
    }

  }
  return { "total": cnt, "offline": cntfail, "critical": cntfail }
}

/**
 * count ont total and offline
 * @param ontList
 * @returns {{offline: number, total: number, critical: number}}
 */
function getONTCount(ontList) {
  let t = 0, o = 0;
  ontList.map(ont => {
    t++;
    if (ont.status === "offline") o++;
  });
  return { "total": t, "offline": o, "critical": o };
}

/***
 * count core NE total, offline
 * @param corenelist
 * @returns {{offline: number, total: number, critical: number}}
 */
function getCoreNECount(corenelist) {
  var cntfail = 0
  var keys = Object.keys(corenelist);
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    var cnt = keys.length
    // eslint-disable-next-line no-loop-func
    let corene = corenelist[key]
    if (corene.status === 'offline') {
      cntfail++
    }
  }
  return { "total": corenelist.length, "offline": cntfail, "critical": cntfail }
}

/**
 * get apiurl for each/all sites --- hard coded
 * @param baseurl
 * @param mysite
 * @returns {*}
 */
function getapiurl(baseurl, mysite) {
  const myendpoint = mysite === 'all' ? '/api/inventory/get_ectivisecloud_allsites' : `/api/inventory/get${mysite}corenes`
  switch (mysite) {
    case 'm2': return baseurl + myendpoint
    case 'feb': return baseurl + myendpoint
    case 'tch': return baseurl + myendpoint
    case 'lg': return baseurl + myendpoint
    case 'all': return baseurl + myendpoint
    default: return baseurl + myendpoint
  }
  // return baseurl + myendpoint
}

function getDATAAPIUrl(baseurlNMSData, siteName){
  return String(baseurlNMSData).concat('', siteName, 'data.json');
}

module.exports = {
  getRandomNumberBetween: (min, max) => getRandomNumberBetween(min, max),
  myapiurl: (baseurl, mysite) => getapiurl(baseurl, mysite),
  getCoreNECount: (corenelist) => getCoreNECount(corenelist),
  getONTCount: (ontList) => getONTCount(ontList),
  coreneTotalCount: (corenelist) => coreneTotalCount(corenelist),
  apTotalCount: (aplist) => apTotalCount(aplist),
  getapiurl: (mysite) => getapiurl(mysite),
  getDATAAPIUrl: (baseurlNMSData, siteName) => getDATAAPIUrl(baseurlNMSData, siteName),
}