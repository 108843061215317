import {useEffect, useState} from 'react'
import axios from 'axios'

const CONFIG = {
  method: 'get',
  url: 'https://falcon.production.engenius.ai/v2/orgs/5fe068a291fddb0893e90761/hvs/5fe068a291fddb0893e90762/networks/5fe068a491fddb0893e9076b/devices/aps',
  headers: {
    'api-key': 'NjQ3M2VmZGY2YjgwNGJh'
  }
};

export function useAxiosGet(config){
  const [request, setRequest] = useState({
    loading: false,
    data: null,
    error: false
  })

  useEffect(() => {
    setRequest({
      loading: true,
      data: null,
      error: false
    })
    axios(CONFIG)
      .then(response => {
        // console.log(JSON.stringify(response.data.aps));
        let newAP_List = {aps: []}
        response.data.aps.forEach(ap => {
          let _ap = {
            name: ap.name,
            model: ap.model,
            mac: ap.mac,
            ip: ap.information.lan.ip,
            wan_ip: ap.information.wan_ip,
            status: ap.information.status,
            // client_count: ap.client_count,
            ping_latency: ap.information.ping_latency,
            cpu_loading: ap.information.cpu_loading,
            mem_usage: ap.information.mem_usage,
            tx_5g: ap.information.tx_power["5G"],
            tx24g: ap.information.tx_power["2_4G"],
            // tx_power: ap.information.tx_power,
            last_seen: ap.information.last_seen,
            current_client_count: ap.information.current_client_count,
            serial_number: ap.serial_number,
            firmware_version: ap.information.firmware_version
          }
          let last_seen = ap.information.last_seen
          _ap.last_seen = timestamp2date(last_seen)
          newAP_List.aps.push(_ap)
        });

        // console.log(newAP_List)
        setRequest({
          loading: false,
          // data: response.data,
          data: newAP_List,
          error: false
        })
      })
      .catch(() => {
        setRequest({
          loading: false,
          data: null,
          error: true
        })
      })
  }, [CONFIG.url])

  return request
}

function timestamp2date(timestamp){
  var ts_ms = timestamp //* 1000
  var date_ob = new Date(ts_ms);
  // year as 4 digits (YYYY)
  var year = date_ob.getFullYear();
// month as 2 digits (MM)
  var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
// date as 2 digits (DD)
  var date = ("0" + date_ob.getDate()).slice(-2);
// hours as 2 digits (hh)
  var hours = ("0" + date_ob.getHours()).slice(-2);
// minutes as 2 digits (mm)
  var minutes = ("0" + date_ob.getMinutes()).slice(-2);
// seconds as 2 digits (ss)
  var seconds = ("0" + date_ob.getSeconds()).slice(-2);
  // let retval = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
  // let retval = year + "-" + month + "-" + date
  return year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
  // return year + "-" + month + "-" + date
}

