import React from "react";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

const Table = props => {
  const { headers, rows } = props;
  return (
    <div >
      <table className="table table-bordered table-hover table-auto">
        <TableHeader className="flex flex-wrap -mb-4" headers={headers} />
        <TableBody className="flex flex-wrap -mb-4" headers={headers} rows={rows} />
      </table>
    </div>
  );
};

export default Table;
