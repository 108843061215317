/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { localized, localdeviceurl, iplist } from '../config';

function DeviceCard({ devicelist }) {
  if (!devicelist) return <div>loading</div>;
  const space3 = <span> </span>;
  const okStyle = { color: 'green' };
  const failStyle = { color: 'red' };
  const fwicon = "https://ec6247ae1be5f73c5b4e3295873e14fb.ectivisecloud.com/images/fortinet.svg"
  const hwicon = "https://ec6247ae1be5f73c5b4e3295873e14fb.ectivisecloud.com/images/huawei.svg"
  if (devicelist.length === 1 && !devicelist[0]) return (<></>)
  // console.log("DeviceCard", devicelist)
  return (
    <>
      {devicelist.map(device => {
        var cpu = device.status === 'online' ? device.cpu + '%' : '-';
        var mem = device.status === 'online' ? device.mem + '%' : '-';
        var _href = localized ? localdeviceurl + device.deviceid : "#";
        var extLink;
        if (device.status === 'online') {
          extLink = (
            <a style={okStyle} href={_href}>
              {device.name}
            </a>
          );
        } else {
          extLink = (
            <a style={failStyle} href={_href}>
              {device.name}
            </a>
          );
        }
        var icon
        if (device.type === 'FW')
          // if (iplist.fw.includes(device.ip)) 
          icon = (<img className='rounded  h-5 w-5' src="/images/fortinet.svg"></img>)
          else if (device.type === 'OLT')
          icon = (<img className='rounded  h-5 w-5' src="/images/olticon.jpg"></img>)
        else if (device.type === 'WIFI' && device.ip !== '10.12.96.3')
            icon = (<img className='rounded  h-5 w-5' src="/images/ruckus.svg"></img>)
        else if (iplist.huawei.includes(device.ip))
          icon = (<img className='rounded  h-5 w-5' src="/images/switch.png"></img>)
        else if (iplist.cisco.includes(device.ip))
          icon = (<img className='rounded  h-5 w-5' src="/images/cisco.svg"></img>)
        else if (iplist.ruckus.includes(device.ip))
          icon = (<img className='rounded  h-5 w-5' src="/images/ruckus.svg"></img>)
        else
          icon = (<img className='rounded  h-5 w-5' src="/images/linux.svg"></img>)
      
        var deviceName = (
          <div className="font-bold text-sm mb-2 flex flex-row">
            <span className="rounded-circle badge-primary text-sm" />
            {icon}
            {/* <img className='rounded  h-5 w-5' src="https://ec6247ae1be5f73c5b4e3295873e14fb.ectivisecloud.com/images/huawei.svg"></img> */}
            {space3}
            {extLink}
            {space3}
          </div>
        );
        return (
          <div
            className="border-4 text-left sm:text-center md:text-right lg:text-justify xl:text-center"
            key={device.name}
          >
            <div className="px-6 py-2">
              {device.type}
              {deviceName}
              <div className="px-2">
                <div className="flex -mx-2 text-sm">
                  CPU:
                  <div className="w-auto px-2">
                    <div className="bg-gray-300 text-black text-sm-left sm:text-center md:text-right lg:text-justify xl:text-center">
                      {cpu}
                    </div>
                  </div>
                  MEM:
                  <div className="w-auto px-2">
                    <div className="bg-gray-300 text-black text-sm-left sm:text-center md:text-right lg:text-justify xl:text-center">
                      {mem}
                    </div>
                  </div>
                  {/*Power :*/}
                  {/*<div className="w-auto px-2">*/}
                  {/*  <div className="bg-gray-300 text-black text-sm-left sm:text-center md:text-right lg:text-justify xl:text-center">{device.power.chassis} W</div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default DeviceCard;
