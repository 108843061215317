import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import Header from './Components/Header'
import Footer from './Components/Footer'
import About from './Views/About'
import HomeFEB from './Views/HomeFEB'
import HomeM2 from './Views/HomeM2'
import HomeLG from './Views/HomeLG'
import HomeTCH from './Views/HomeTCH'
import HomeENG from './Views/HomeSurbana'
import HomeAlarm from './Views/HomeAlarm'
import useToken from './useToken';
import Login from "./Components/Login/Login";
import {noLogin} from "./config" 
import HomeTable from './Components/HomeTable';
import Navigation from "./Components/Navigation_new";

//TODO:
/**
 1) show NMS server status for all sites
 2) show sensors status, especially the HA, etc
 3) global search mac address from CS, OLT, ONT eth port, etc
 4) notification on page
 8) user management
 9) login/out
 10) ONT list toggle collapse/expands
 11) graphs/charts on each page
 12) database auto housekeeping
 15) check and ensure all api endpoints authenticated

Add 3 features
 * site settings
 * user management
 * notification for all ALARMS, especially for UOB sites
 * highlight tab actie
 * auto switch tab
 */

function App() {
  const { token, setToken } = useToken();
  if (!noLogin && !token) {
    return <Login setToken={setToken} />
  }
  return (
    <div className="relative pb-10 min-h-screen">
      <Router>

        <Header />
        <Navigation />

        <div className="p-3">
          <Switch>
            <Route exact path="/">
            <HomeTable />
            </Route>
            <Route path="/feb">
              <HomeFEB />
            </Route>
            <Route path="/m2">
              <HomeM2 />
            </Route>
            <Route path="/lg">
              <HomeLG />
            </Route>
            <Route path="/tch">
              <HomeTCH />
            </Route>
            <Route path="/alarm">
              <HomeAlarm />
            </Route>
            <Route path="/eng">
              <HomeENG />
            </Route>
            <Route path="/about">
              <About />
            </Route>
          </Switch>
        </div>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
