import React, { useMemo } from 'react';
import Loader from '../Components/Loader'
import { useAxiosGet } from '../Hooks/HttpRequests'
import { Container } from 'reactstrap';
import { SelectColumnFilter } from '../Components/filters';
import TableContainer from '../Components/TableContainer';
import { myapiurl } from '../config';

const apiurl = myapiurl('alarm')
function HomeAlarm() {
  const alarmTitle = 'Alarm History'
  let getontresponse = useAxiosGet(apiurl)
  const columns = useMemo(
    () => [
      {
        Header: 'Site',
        accessor: 'site',
        width: 15,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Datetime',
        accessor: 'datetime',
      },
      {
        Header: 'AlarmId',
        accessor: 'id',
        disableSortBy: false,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Discription',
        accessor: 'desc',
        disableSortBy: true,
      },
      {
        Header: ' Severity',
        accessor: 'type',
        // disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      // {
      //   Header: 'Severity',
      //   accessor: 'severity',
      //   // disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      // },
      //{
        //Header: 'Device Type',
        //accessor: 'device',
        //disableSortBy: true,
        //Filter: SelectColumnFilter,
        //filter: 'equals',
      //},
      // {
      //   Header: 'ONT',
      //   accessor: 'ontid',
      //   disableSortBy: true,
      //   Filter: SelectColumnFilter,
      //   filter: 'equals',
      // },
      {
        Header: 'OLT',
        accessor: 'oltid',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Params',
        accessor: 'params',
        disableSortBy: true,
        // Filter: SelectColumnFilter,
        // filter: 'equals',
      },
      {
        Header: 'Cause',
        accessor: 'cause',
        disableSortBy: true,
      },
      {
        Header: 'Advice',
        accessor: 'advice',
        disableSortBy: true,
      },
    ],
    []
  );

  // error handling while there's error, e.g. use http for https api url
  if (getontresponse.error) {
    var content = <div>
      <div className="bg-blue-300 mb-2 p-3">
        If you see this error. Please remember to create your own <a href="https://mockapi.io/">mock API</a>.
      </div>
      <div className="bg-red-300 p-3">
        There was an error please refresh or try again later.
      </div>
    </div>
  }

  // will show loading animation while calling the API
  if (getontresponse.loading) {
    // content = <Loader/>
    return <Loader />
  }

  // data ready
  if (getontresponse.data) {
    const resData = getontresponse.data;
    // console.log('home resData==>', resData)
    var febAlarmList = resData.febAlarms
    var m2AlarmList = resData.m2Alarms
    febAlarmList.forEach(alarm =>{
      alarm.site = 'FEB'
    })
    m2AlarmList.forEach(alarm =>{
      alarm.site = 'M2'
    })
    febAlarmList.sort((b, a) => a.id - b.id);
    m2AlarmList.sort((b, a) => a.id - b.id);
    //console.log('febAlarmList==>', febAlarmList)
    // console.log('m2AlarmList==>', m2AlarmList)
    const allAlarms = [].concat(febAlarmList, m2AlarmList)

    content = <Container style={{ marginTop: 20 }}>
      <TableContainer
        columns={columns}
        data={allAlarms}
      />
    </Container>
  }

  return (
    <div className="container mx-auto">
      <div className="font-bold text-2xl mb-3 pt-3">
        {alarmTitle}
      </div>
      <div className="md:flex flex-wrap md:-mx-3">
        {content}
      </div>

    </div>
  )
}

export default HomeAlarm
