import {useEffect, useState} from 'react'
import {apiheaders} from '../config';
var axios = require('axios');
var qs = require('qs');
var data = qs.stringify({});
export function useAxiosGet(url){
    // console.log(url)
    const [request, setRequest] = useState({
        loading: false,
        data: null,
        error: false
    })

    useEffect(() => {
        setRequest({
            loading: true,
            data: null,
            error: false
        })
        var config = {
            method: 'get',
            url: url,
            headers: apiheaders,
            data : data
          };
          axios(config)
            .then(response => {
                setRequest({
                    loading: false,
                    data: response.data,
                    error: false
                })
            })
            .catch(() => {
                setRequest({
                    loading: false,
                    data: null,
                    error: true
                })
            })
    }, [url])

    return request
}
