import React from "react";
import schema from "./subsysschema.json";
import Table from "./Table";
export default function SubSystem(row) {
  // const urlsub = 'http://localhost:8081/api/devices/get_subsystem'
  const {
    // port, id, name, site, block, level, room, ontid, status, lastuptime, lastdowntime, lastdowncause, datetime, tx, rx,type,
    // port, id,
    type,
    // riser,
    level,
    lastdowncause,
    lastuptime,
    lastdowntime,
    gaussian,
    floorplan,
    sn, distance, status,
    // cpu, traffic,
    ethports,
    // subsystems
  } = row.original;
  // console.log(status)
  console.log("ethports:", ethports)
  const subsystems = ethports
  //TODO SUBSYS: get the subsystem from the port and id
  const ontDetail = <table>
    <tbody>
      <tr>
        <td><h6 className="align-middle text-black pl-10">SN: <span
          className="text-justify pt-2">{sn}</span></h6>
        </td>
        <td>
          <h6 className="align-middle text-black pl-10">Model: <span className="text-justify pt-2">{type}</span>
          </h6>
        </td>
        <td>
          <h6 className="align-middle text-black pl-10">Distance: <span
            className="text-justify pt-2">{distance}</span></h6>
        </td>
        <td>
          <h6 className="align-middle text-black pl-10">lastdowncause: <span
            className="text-justify pt-2">{lastdowncause}</span></h6>
        </td>
        <td>
          <h6 className="align-middle text-black pl-10">lastdowntime: <span
            className="text-justify pt-2">{lastdowntime}</span></h6>
        </td>
        <td>
          <h6 className="align-middle text-black pl-10">lastuptime: <span
            className="text-justify pt-2">{lastuptime}</span></h6>
        </td>
        {/* <td><h6 className="align-middle text-black pl-10">cpu %: <span
          className="text-justify pt-2">{cpu}</span></h6>
        </td>
        <td>
          <h6 className="align-middle text-black pl-10">traffic (bps): <span className="text-justify pt-2">{traffic}</span>
          </h6>
        </td> */}
      </tr>
      {/*<tr>*/}
      {/*  <td><h6 className="align-middle text-black pl-10">level: <span*/}
      {/*    className="text-justify pt-2">{level}</span></h6>*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <h6 className="align-middle text-black pl-10">riser: <span className="text-justify pt-2">{riser}</span>*/}
      {/*    </h6>*/}
      {/*  </td>*/}
      {/*</tr>*/}
      {/*<tr>*/}
      {/*  <td><h6 className="align-middle text-black pl-10">cpu: <span*/}
      {/*    className="text-justify pt-2">{cpu}</span></h6>*/}
      {/*  </td>*/}
      {/*  <td>*/}
      {/*    <h6 className="align-middle text-black pl-10">traffic: <span className="text-justify pt-2">{traffic}</span>*/}
      {/*    </h6>*/}
      {/*  </td>*/}
      {/*</tr>*/}
    </tbody>
  </table>

  let subSys = null
  for (let i = 0; i < subsystems.length; i++) {
    subsystems[i][status] = <h3>subsystems[i][status]</h3>
    // console.log(subsystems[i])
  }
  if (subsystems) {
    subSys = <div className="container p-2">
      <div className="row">
        <div className="col">
          <Table headers={Object.keys(schema)} rows={subsystems} />
        </div>
      </div>
    </div>

  }
  let subContents =
    <div>
      {ontDetail}
      {subSys}
    </div>
  let floorplanContent = null
  let _floorplan = !floorplan ? "L5.jpg" : floorplan
  if (_floorplan) {
    let floorplanUrl = `https://sr.ectivisecloud.com/graph/${_floorplan}`
    // console.log(_floorplan)
    // console.log(floorplanUrl)
    floorplanContent =
      <div>
        <strong className="p-4">{level} Floorplan</strong>
        {/*<img src={gaussianUrl} alt='gaussian distribution' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid"/>*/}
        <img src={require(`../graph/${_floorplan}`)} alt='floorplan' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid" />
      </div>
  }
  let gaussianContent = null
  let _gaussian = !gaussian ? "LG-M10-1016-ONT10.png" : gaussian

  if (_gaussian && status !== "offline") {
    let gaussianUrl = `https://sr.ectivisecloud.com/graph/${_gaussian}`
    // console.log(gaussianUrl)
    gaussianContent =
      <div><br /><strong className="p-4">Gaussian Distribution</strong>
        {/* <img src={gaussianUrl} alt='gaussian distribution' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid"/> */}
        <img src={require(`../graph/${_gaussian}`)} alt='gaussian distribution' className="img-responsive bg-blue bg-cover max-h-full h-auto img-fluid" />
      </div>
  }
  return (
    <div>
      {subContents}
      {/* {floorplanContent}
      {gaussianContent} */}
    </div>
  );
};
