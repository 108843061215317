import React from 'react'
// import Navigation_new from './Navigation_new'
import { Link } from 'react-router-dom'
let espllogo = "https://api.ectivisecloud.com/images/baelogo.png"

const isMobile = window.innerWidth < 650;

function Header() {
  let content = !isMobile? <header className="border-b p-3 flex justify-between items-center flex-auto">
  <Link to="/" className="font-bold">
    <div className="row pl-4">
      <img className="full-width height: md:m-auto" src={espllogo} height={50} width={50} alt="uob logo" />
      {/* <img className="max-w-full h-auto" src={espllogo} alt="ectivise logo" /> */}
      <div className="px-4 text-2xl">Network Monitor System</div>
    </div>
  </Link>
</header>
:
<header className="border-b p-3 flex justify-between items-center flex-auto">
<Link to="/" className="font-bold">
  <div className="row pl-3">
    <img className="full-width height: md:m-auto" src={espllogo} alt="uob logo" />
	{/*<img className="px-4 w-48 md:w-32 lg:w-48" src={espllogo} alt="ectivise logo" />*/}
     {/* <img className="max-w-full h-auto" src={espllogo} alt="ectivise logo" /> */}
    <div className="px-4 px-4 text-xl">Network Monitor System</div>
  </div>
</Link>
</header>
  return (
    <div className="md:container md:mx-auto">
    {content}
   {/*<Navigation_new />*/}
   </div>
  )
}

export default Header
