import React from "react";

const TableHeader = props => {
  const { headers } = props;
  return (
    <thead className="thead-dark border-2" key="header-1">
    <tr key="header-0">
      {headers &&
      headers.map((value, index) => {
        const pwindex = 3
        return (
          <th key={index}>
            {/* {index === pwindex? <div>{value}(W)</div>:<div>{value}</div>} */}
            {index === pwindex? <div>{value}</div>:<div>{value}</div>}
          </th>
        );
      })}
    </tr>
    </thead>
  );
};

export default TableHeader;
/*
description: "LG-MB1-ENG-ONT01"
ip: ""
oltport: "000"
ontid: "1"
ontport: "1"
sn: "485754432EC7769C"
spare: true
status: "online"
system: "DATA"
vlan: "140"
_id: "5f59ff9c27990a1e50a4545c"
 */
