const site = 'feb'; // 'm2' or 'feb'
const localized = false;
const noLogin = false;
const useNMSserver = false;
const useLocalAPI = false;
const localHost = '192.168.64.24'
const localPort = '8082'
const endpoint =  `/api/inventory/get${site}corenes`;
var baseurl = `http://${localHost}:${localPort}`;
const urlapi = 'https://api.ectivisecloud.com';
const urlnms = 'https://nms.ectivisecloud.com';
const baseurlNMSData = 'https://api.ectivisecloud.com/files/'
//be sure the API is using https
const apiheaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'Cookie': 'connect.sid=s%3AYDkwnvZtoSkKr05mVIqmWH7kEk-NKXu5.44g1sp3S0IK46iCRZ1ldxlwYhnB%2FYw%2FMZdUvZHzHxbQ',
  'Access-Control-Allow-Origin': '*',
  'x-api-key': 'NjQ3M2VmZGY2YjgwNGJh',
}
if (!useLocalAPI){
  baseurl = useNMSserver ? urlnms : urlapi;
}
const IPList = {
  "huawei": [
    "10.12.10.1",
    "192.168.190.3",
    "192.168.190.8",
    "192.168.170.2",
    "192.168.170.7",
    "192.168.170.8",
    "192.168.64.6",
    "192.168.11.5",
    "192.168.11.6"],
  "fw": [
    "10.12.0.2",
    "192.168.2555.1"],
  "ruckus": [
    "192.168.56.2",
    "192.168.56.3",
    "192.168.80.4",
    "192.168.80.5",
    "192.168.56.4"],
  "cisco": [
    "192.168.11.1","192.168.11.7","192.168.64.1"],
  "windows": [
  ]
}
function getapiurl(mysite) {
  const myendpoint = mysite === 'all' ? '/api/inventory/get_ectivisecloud_allsites': `/api/inventory/get${mysite}corenes`;
  switch (mysite) {
    case 'm2': return baseurl + myendpoint;
    case 'feb': return baseurl + myendpoint;
    case 'tch': return baseurl + myendpoint;
    case 'lg': return baseurl + myendpoint;
    case 'alarm': return baseurl + '/api/inventory/getAllAlarms_jQ3M2VmZGY2YjgwNGJh';
    case 'all': return baseurl + myendpoint;
    default: return baseurl + myendpoint;
  }
}

// function getDATAAPIUrl(baseurlNMSData, siteName){
//   return String(baseurlNMSData).concat('', siteName, 'data.json');
// }

module.exports = {
  homeTitle: site === 'm2'? 'UOB Plaza I':'UOB FEB',
  homeTitleLG: 'Le Grove Serviced Residences',
  homeTitleTCH: 'The Clan Hotel',
  homeTitleM2: 'UOB Plaza I',
  homeTitleFEB: 'UOB FEB',
  homeTitleENG: 'Surbana House Access Points',
  localweburl: `https://uob${site}.ectivisecloud.com`,
  localdeviceurl: `https://uob${site}.ectivisecloud.com/device/`,
  localized: localized, //localization will show the device id link to return to the librenms device show
  debug: true,
  baseurl: baseurl,
  engapiurl: "https://falcon.production.engenius.ai/v2/orgs/5fe068a291fddb0893e90761/hvs/5fe068a291fddb0893e90762/networks/5fe068a491fddb0893e9076b/devices/aps",
  baseurlNMSData: baseurlNMSData,
  myapiurl: (mysite) => getapiurl(mysite),
  apiheaders: apiheaders,
  noLogin: noLogin,
  iplist: IPList,
  fetchInterval: 180 //31536000
};
