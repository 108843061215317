import React from "react";
// import OLTCard from "./OLTCard";
import DeviceCard from "./DeviceCard";
import MACCard from "./MACCard";

// import UPSCard from "./UPSCard";
// import Schneider from "./Schneider";
function CoreNECard({corenelist}){
  const fwList = corenelist.fwlist? <DeviceCard devicelist={corenelist.fwlist}/>: '';
  const wswList = corenelist.wswlist? <DeviceCard devicelist={corenelist.wswlist}/>: '';
  const csList = corenelist.cslist? <DeviceCard devicelist={corenelist.cslist}/>: '';
  const oltlist = corenelist.oltlist? <DeviceCard devicelist={corenelist.oltlist}/>: '';
  // const pwlist = corenelist.pwlist? <DeviceCard devicelist={corenelist.pwlist}/>: '';
  const wifilist = corenelist.wifilist? <DeviceCard devicelist={corenelist.wifilist}/>: '';
  const maclist = corenelist.maclist? <MACCard devicelist={corenelist.maclist}/>: '';
  // <MACCard devicelist={corenelist.macsum}/>
  // console.log('corenecard=>', corenelist);
  // console.log('maclist=>', maclist);
  return (
    <div className="container">
      <div className="row">
        <div className="container">
        <div className="row">
            {fwList}
            {csList}
            {wswList}
            {wifilist}
            {oltlist}
            {maclist}
          </div>
          <div className="row">
            {/* <DeviceCard devicelist={corenelist.fwlist}/> */}
            {/* <DeviceCard devicelist={corenelist.cslist}/> */}
            {/* <UPSCard devicelist={corenelist.upslist}/> */}
            {/* <Schneider devicelist={corenelist.schneider}/> */}
          </div>
        </div>
        <div className="container">
          <div className="row">
            {/*<DeviceClusterCard devicelist={corenelist.wlclist}/>*/}
            {/* <DeviceCard devicelist={corenelist.wanclist}/> */}
            {/*<DeviceOLTCard devicelist={corenelist.oltlist}/>*/}
            {/*<OLTCard devicelist={corenelist.oltlist}/>*/}
          </div>
        </div>
        <div className="container">
          <div className="row">
            {/*<DeviceClusterCard devicelist={corenelist.wlclist}/>*/}
            {/*<DeviceOLTCard devicelist={corenelist.oltlist}/>*/}
            {/* <OLTCard devicelist={corenelist.oltlist}/> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoreNECard
