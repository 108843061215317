import React, {useMemo, useState} from 'react';
import Loader from '../Components/Loader';
import {useAxiosGet} from '../Hooks/HttpRequests_v2';
// import {useAxiosGet} from '../Hooks/HttpRequests';
import {Container} from 'reactstrap';
import {SelectColumnFilter} from '../Components/filters';
import TableContainer from '../Components/TableContainer_v2';
// import columns from '../utils/MockData';
import CoreNECard from "../Components/CoreNECard";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import {coreneTotalCount, getONTCount} from '../utils/MockData';
import {homeTitleFEB as homeTitle, myapiurl, fetchInterval} from '../config';
const apiurl = myapiurl('feb');
//TODO: code housekeeping: use unique code for all Homex.js 
function HomeFEB(){
  const [isActive, setActive] = useState("true");
  const [intval, setIntval] = useState(fetchInterval);
  let content = null, coreneContent;
  let total, offline, ontTotal, ontCritical, ontOffline, critical;
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander', // 'id' is required
        Cell: ({row}) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? '👇' : '👉'}
          </span>
        ),
      },
      {
        Header: 'Name',
        accessor: 'description',
      },
      {
        Header: 'Port',
        accessor: 'port',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'ID', accessor: 'id', width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: 'Rx',
        accessor: 'rx',
        disableSortBy: true,
        // Cell: row => {
        //   row.styles['backgroundColor'] = row.status === 'online' && row.value < -20 ? '#cc3300' : 'inherit';
        //   return row.status === 'online' ? row.value : null;
        // }
      },
      {
        Header: 'Tx',
        accessor: 'tx',
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              background: rowInfo && rowInfo.row.age > 10 ? 'red' : null,
            },
          };
        },
      },
    ],
    []
  );

  const handToggle = () => {
    setActive(!isActive);
    if(isActive){
      setIntval(3600);
    }
      else{
        setIntval(fetchInterval);
      }
  };
  var btntext = isActive?'pause':'refresh'
let getontresponse = useAxiosGet(apiurl, intval, isActive);
  if(getontresponse.error){
    content = <div>
      <div className="bg-blue-300 mb-2 p-3">
        If you see this error. Please remember to create your own <a href="https://mockapi.io/">mock API</a>.
      </div>
      <div className="bg-red-300 p-3">
        There was an error please refresh or try again later.
      </div>
    </div>
  }

  // will show loading animation while calling the API
  if(getontresponse.loading){
    return <Loader/>
  }

  // data ready
  if(getontresponse.data){
    const resData = getontresponse.data;
    // console.log('homefeb resData==>', resData)
    var ontlist = resData.onts
    var coreneList = resData.corenes
    var macsum = resData.macsum
    // console.log('homefeb macsum==>', macsum)
    let oltlist = [] 
    let wswlist = [] 
    let fwlist = [] 
    let cslist = []
    let wifilist = []
    let pwlist = []
    coreneList.forEach(ne=>{
      switch(ne.type.toUpperCase()){
        case 'OLT':
          oltlist.push(ne);
          break;
        case 'FW':
          fwlist.push(ne)
          break;
        case 'CS':
          cslist.push(ne)
          break;
        case 'WSW':
          wswlist.push(ne)
          break;
        case 'WIFI':
          wifilist.push(ne)
          break;
        case 'POWER':
          pwlist.push(ne)
          break;
        default:
          break;
        }
    })
    var corenelist = {"cslist":cslist,"fwlist":fwlist,"oltlist":oltlist,"wswlist":wswlist,"wifilist":wifilist}
    // console.log('homefeb corenelist==>', corenelist)

    ontlist.forEach(ont=>{
      if (ont.status === 'offline'){
        ont.rx = '-';
        ont.tx = '-';
      }
    })
    const ct = coreneTotalCount(corenelist)
    total = ct.total
    offline = ct.offline
    critical = ct.critical===0? <a style={{color: "green"}}>0</a>: <a style={{color: "red"}}>{ct.critical}</a>
    corenelist.maclist = macsum

    var ontdatetime = ontlist[0].datetime
    var corenedatetime = oltlist[0].datetime

    const cnt = getONTCount(ontlist)
    ontTotal = cnt.total
    ontOffline = cnt.offline
    ontCritical = cnt.offline===0? <a style={{color: "green"}}>0</a>: <a style={{color: "red"}}>{cnt.offline}</a>
    content = <Container style={{marginTop: 20}}>
      <TableContainer
        columns={columns}
        data={ontlist}
      />
    </Container>
    coreneContent = <CoreNECard corenelist={corenelist}/>
  }
  const btn = <button id="pauseInterval" className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" onClick={(event) => handToggle(event)}>{btntext}</button>

  return (
    <div className="container mx-auto">
      <div className="font-bold text-2xl mb-3 px-2 pt-2">
        <div>{homeTitle}</div>
        <div className="font-bold text-2xl mb-3 pt-1">Core NEs
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Total {total}</span>
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Offline {offline}</span>
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Critical {critical }</span>
          <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">last updated@{corenedatetime}</span>
          {btn}
          </div>
      </div>
      {coreneContent}

      <div className="font-bold text-2xl mb-3 pt-2">
        ONTs
      </div>

      <Accordion>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Total {ontTotal}</span>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Offline {ontOffline}</span>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">Critical {ontCritical }</span>
                <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">last updated@{ontdatetime}</span>
                </div>
            </AccordionItemButton>
            </AccordionItemHeading>
          <AccordionItemPanel>
            <div className="md:flex flex-wrap md:-mx-3">
              {content}
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

    </div>
  )
}

export default HomeFEB
