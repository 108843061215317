import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { baseurl, debug } from '../../config'
// import otpValidation from './otpValidation';

const endpoint = '/api/users/login'
const endpoint1 = '/api/users/resetPassword'
let loginurl = baseurl + endpoint
let reseturl = baseurl + endpoint1
export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [error, setError] = useState("");
  const [show, setShow] = useState(true);
  const [reset, setReset] = useState(false);
  const [otp, setOtp] = React.useState("");
  const inputRef = useRef(null)

  const errorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  }

  function toggleShow() {
    setShow(!show)
  }

  function toggleRest() {
    setReset(!reset)
  }

  function refreshPage() {
    window.location.reload(false);
  }

  async function loginUser(credentials) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    myHeaders.append("Cookie", "connect.sid=s%3AG5mJ6hrcojLszUwfE4-lMabzaY0bRMLR.UOfVs9%2BVrqD33HYACcBJtpqw75N7zv6Gh314LAF6Go0");

    var urlencoded = new URLSearchParams();
    urlencoded.append("token", "ectivisecloudDBAuthCode:b84846daf467cede0ee462d04bcd0ade");
    urlencoded.append("appid", "0");
    urlencoded.append("type", "mobile");
    urlencoded.append("mobile", credentials.username);
    urlencoded.append("password", credentials.password);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch(loginurl, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result)
        if (data.errorCode !== 0) {
          setError("Wrong username or password");
          setTimeout(() => setError(""), 3000);
        }
        else {
          if (debug) {
            console.log("verifyCode=", data.data.verifyCode)
            // debugger
          }
          const verifyCode = data.data.verifyCode || data.data.sms
          toggleShow()
          setOtp(verifyCode.toString())
        }
        return -1
      })
      .catch(error => console.log('error', error));
  }

  async function otpVerification(credentials, otp) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("token", "ectivisecloudDBAuthCode:b84846daf467cede0ee462d04bcd0ade");
    urlencoded.append("appid", "0");
    urlencoded.append("type", "2fa");
    urlencoded.append("mobile", credentials.username);
    urlencoded.append("password", credentials.password);
    urlencoded.append("otp", otp);
    // debugger
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch(loginurl, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result)
        // console.log(data);
        if (data.errorCode !== 0) {
          setError(data.message);
          setTimeout(() => setError(""), 3000);
          return { token: false }
        }
        else {
          toggleShow()
          return { token: true }
        }
      })
      .catch(error => console.log('error', error));
  }

  async function changePassword(username, newpassword) {
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "connect.sid=s%3AQlpgNoP8a5bIrQoq7iTs6664QMVT8Bs0.NDmtxWxfj%2BznelzEEaqVMadc7euWXuWDKPPNkK3u9MA");

    var formdata = new FormData();
    formdata.append("mobile", username);
    formdata.append("token", "ectivisecloudDBAuthCode:b64226daf467cede0ee882d04bcd0ade");
    formdata.append("password", newpassword);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    return fetch(reseturl, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result)
        // console.log(data);
        if (data.errorCode !== 0) {
          setError(data.message);
          setTimeout(() => setError(""), 3000);
          return false
        }
        else {
          console.log(data)
          return true
          // toggleShow()
          // return { token: true }
        }
      })
      .catch(error => console.log('error', error));
  }


  const handleLoginSubmit = async e => {
    e.preventDefault();
    console.log(e)
    // debugger
    await loginUser({ username, password });
  }

  const handleOtpSubmit = async e => {
    e.preventDefault();
    const token = await otpVerification({ username, password }, otp);
    setToken(token);
  }

  const handleResetSubmit = async e => {
    e.preventDefault();
    // console.log("newpassword: ", newpassword)
    if (newpassword) {
      const changed = await changePassword(username, newpassword);
      // console.log("response=", response)
      if (changed) {
        setError("password changed successfully")
        setTimeout(() => setError(""), 2000);
        refreshPage()
      }
      else {
        setError("password change failure")
        setTimeout(() => setError(""), 2000);
        refreshPage()
      }

    }
    else {
      setError("password cannot be empty")
      setTimeout(() => setError(""), 2000);
    }
  }

  const handleRestEvent = async e => {
    e.preventDefault();
    // console.log("new password", newpassword)
    if (username) {
      toggleRest(reset)
    }
    else {
      setError('Please key in your phone number.')
      setTimeout(() => setError(""), 2000);
    }

    // const token = await otpVerification({ username, password }, otp);
    // setToken(token);
  }

  // const phone = 100
  const resetContent = <div>
    <div className='container'>
      {/* <h5 className="text-center text-sm text-gray-900">{username}</h5> */}
      <div className="mb-6">
      <label for="success" className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500">Mobile: {username}</label>
      <input type="text" id="success" className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500 py-py"
        onChange={(event) => setNewpassword(event.target.value)}
        placeholder="Enter new password"
      /></div>
      <button onClick={handleResetSubmit} className="top-3 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded py-py">Submit</button>
      {/* <a className = 'error'>{error}</a> */}
    </div>
  </div>

  const errContent = <div><a className='error'>{error}</a></div>
  const validateContent =
    <div className='container'>
      <h6>Enter the OTP sent to you</h6>
      <input
        onChange={(event) => setOtp(event.target.value)}
        placeholder="Enter 6 digit OTP"
        className='input'
      />
      <button onClick={handleOtpSubmit} className="top-3 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded py-py">Submit</button>
      {/* <a className = 'error'>{error}</a> */}
    </div>

  const loginForm =
    <form className="mt-8 space-y-6" action="#" onSubmit={handleLoginSubmit}>
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="username" className="sr-only">user</label>
          <input onChange={e => setUserName(e.target.value)} id="username" name="username" type="text" required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="registered mobile" ref={inputRef} />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">Password</label>
          <input onChange={e => setPassword(e.target.value)} id="password" name="password" type="password"
            autoComplete="current-password" required
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            placeholder="your password" />
        </div>
      </div>
      <div>
        <button type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            {/* Heroicon name: solid/lock-closed */}
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clipRule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>
        <div className="flex justify-center my-4">
          <button onClick={handleRestEvent} className='submit'>Forgot password?</button>
        </div>
      </div>
    </form>

  const loginContent =
    <div>
      <div>
        <img className="mx-auto h-12 w-auto" src="https://api.ectivisecloud.com/images/baelogo.png" alt="espl log" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Please Sign in
        </h2>
      </div>
      {loginForm}
    </div>

  const resetContent1 =
    <div>
      <div>
        <img className="mx-auto h-12 w-auto" src="https://api.ectivisecloud.com/images/baelogo.png" alt="espl log" />
        <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">
        Charnge Password 
        </h2>
      </div>
      {resetContent}
    </div>

const validateContent1 =
<div>
  <div>
    <img className="mx-auto h-12 w-auto" src="https://api.ectivisecloud.com/images/baelogo.png" alt="espl log" />
    <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">
    OTP Validation 
    </h2>
  </div>
  {validateContent}
</div>

  return (
    <div className="login-wrapper">
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          {show && !reset && loginContent}
          {!show && validateContent1}
          {reset && resetContent1}
          {errContent}
        </div>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
